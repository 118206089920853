<template>
    <div class="header">
        <!-- <Button icon="pi pi-bookmark" class="p-button-rounded p-button-secondary" /> -->
    </div>
</template>

<script>
export default {};
</script>
<style scoped>
.header {
    background: #24566c;
    /* height: 3em; */
    min-height: 36pt;
}
</style>